import React from 'react';
import '../App.css';
import logo from "../logo.svg";
// import app from "../app-store-download.svg"
import { BrowserView, MobileView } from 'react-device-detect';
import {Link} from 'react-router-dom';

function Home() {
  return (
    <>

      <BrowserView>

        <div className="App">

          <header className="App-header-web">
          
            <p>
              Humankind Inc.
            </p>

            <a href="https://apps.apple.com/us/app/click-squad-up/id1604534600">
              <img src={logo} className="App-logo" alt="logo" />
            </a>
            
        
          </header>

          <footer className="web-footer">
            <Link to="/terms" className="link">
                Terms of Service &nbsp;
            </Link>
            <Link to="/privacy" className="link">
                &nbsp; Privacy Policy
            </Link>
            
          </footer>
        </div>
      </BrowserView >

      <MobileView>
        <div className="App">
          <header className="App-header">
              
            Humankind Inc.
            <br></br>
            <a href="https://apps.apple.com/us/app/click-fun-weekends/id1604534600">
                <img src={logo} className="App-logo-mobile" alt="logo" />
            </a>
          </header>
  
          <footer className="footer">
            <Link to="/terms" className="link">
                Terms of Service &nbsp;
            </Link>
            <Link to="/privacy" className="link">
                &nbsp; Privacy Policy
            </Link>
            
          </footer>
        </div>
      </MobileView>
    </>
  );
}

export default Home;
