import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../logo192.png";
import '../App.css';

function Terms() {

    return (

        <div className="App-content">

            <Link to="/" className="link">

                <img src={logo} className="logo-circle" alt="logo" />
            </Link>

            <h1>Terms of Service</h1>

            <h2>Agreement</h2>

            <p>By signing up for Humankind Inc., you agree to the following Terms of Service and are responsible for complying with them. 
                If you are accepting these Terms and using the Service on behalf of a company, organization, government, or other 
                legal entity, you represent and warrant that you are authorized to do so and have the authority to bind such entity 
                to these Terms, in which case the words “you” and “your” as used in these Terms shall refer to such entity. 

                We reserve the right to make changes to this agreement at any given time.
            </p>

            <h2>Eligibility</h2>

            <p>By agreeing to use Humankind Inc., you state that: </p>
            
            <ul>
                <li>
                    You form a binding contract with Humankind Inc..
                </li>
                <li>You are not a person who is barred from using the Service under the laws of the United States or any
                    other applicable jurisdiction–meaning that you do not appear on the U.S. Treasury Department’s list
                    of Specially Designated Nationals or face any other similar prohibition.
                </li>
                <li> You will comply with this Agreement and all applicable local, state, national and international laws, rules and regulations.
                </li>

            </ul>
            <h2>User Account</h2>
            <p>If you sign up for Humankind Inc. using your Facebook account, we reserve the right to collect and use personal data including but not limited
                to your public Facebook profile and your Facebook friends you share in common with other Humankind Inc. users. If you want more information on
                how we collect and use your personal data, please refer to our <Link to="/privacy" className="privacyLink">Privacy Policy</Link>
                . You are responsible for keeping your login credentials private.

            </p>

            <h2>Termination</h2>
            <p>If you violate the agreement you have made with Humankind Inc., your account may be suspended or terminated based
                on our sole discretion.
            </p>
            <h2>Modifying the Service
            </h2>
            <p>We reserve the right to modify or suspend our Service at any given time without prior notice.

            </p>
            <h2>User Responsibilities

            </h2>
            <p>Humankind Inc. users are not guaranteed to see all current buzzes that have been created on Humankind Inc.. The owners of buzzes are able to limit their buzzes 
                to only a select group of users by setting filters on to whom the buzz is visible. If you a user does not match the criteria for a given buzz, 
                they won’t be able to see it. 

                You are responsible for conduct and interactions with other users on Humankind Inc.. Any improper interaction or content, as specified by our Community
                Guidelines, is possible grounds for termination or suspension of your account. There is no tolerance for objectionable content or abusive users.

                You agree that all personal profile information you provide is accurate and truthful.
                You agree that Humankind Inc. may access, preserve and disclose your account information if required by the law or otherwise necessary.

                You agree to give up your right to sue Humankind Inc. in the court of Law and participate in any class action law suite or other class proceedings against Humankind Inc.. 




            </p>
            <h2>Liability Disclaimers

            </h2>
            <p>Humankind Inc. does not claim any responsibility for the following:

            </p>
            <ul>
                <li>Humankind Inc. is not liable for any activities planned in buzzes.
                </li>
                <li>Humankind Inc. is not liable for the validity and content of third party links posted in our app. 
                </li>
                <li>Humankind Inc. is not liable for guaranteeing the accuracy of any content posted on our Service.
                </li>
                <li>While we do our best to enforce Community Guidelines, Humankind Inc. is not liable for any content posted by our users. </li>
                <li>Humankind Inc. is not responsible for conduct on its Service or the conduct of users outside of the Service. 
                </li>
                <li>Humankind Inc. does not conduct criminal background checks on its users and does not look into the background of its users in any way. 
                </li>
                <li>FHumankind Inc. is not liable for guaranteeing the quality of its Service or the accessibility to Service in every jurisdiction.
                </li>
                <li>Humankind Inc. does not provide any compensation in any form for any claim, judicial or otherwise.</li>

            </ul>
            <h2>Licensing and Access
            </h2>
            <p>Humankind Inc. gives you a limited license to access and use this Service. Therefore, you agree not to:
            </p>
            <ul>
                <li>Modify the Service in any way
                </li>
                <li>Use manual or automatic device or method to extract the content of the Service
                </li>
                <li>Use or Transmit any copyrighted material and intellectual property
                </li><li>Use Humankind Inc.'s properties on any website
                </li><li>Imply that you are endorsed by Humankind Inc.
                </li> <li>Develop any applications that interact with the Service without our written consent</li>
                <li>Interfere or disrupt the Service in any way</li>
                <li>Insert viruses or other malicious code into the Service</li>
                <li>Scan or test the Service or any network</li>
                <li>Copy or mirror any part of the Service</li>
                <li>Modify, distribute, or disassemble any part of the Service</li>
                <li class="li2">Use the Service for any commercial purposes</li>
                <li class="li2">Disguise the source of any information transmitted on the Service</li>
            </ul>
            <h2>Humankind Inc.'s Rights
            </h2>
            <p>By creating an account, you give Humankind Inc. the right to host, use, copy, display, reproduce, distribute, and modify any personal information you provide Humankind Inc. by
                linking your Facebook account or by posting it on Humankind Inc.. You agree that any content you post on our Service may be viewed by other users.
            </p>
            <h2>Community Guidelines
            </h2>
            <p>All users have to adhere to the following Community Guidelines:
            </p>
            <ul>
                <li>All photos and videos posted on Humankind Inc. have to be uploaded by the author or by a user who has permission to post the photos or videos.
                </li>
                <li>All users must respect other users’ posts.
                </li>
            </ul>
            <p>By agreeing with our Community Guidelines, you agree not to:</p>
            <ul>
                <li>Modify the Service. This may be only done by the Humankind Inc. team. Feel free to email us if you see something wrong.</li>
                <li>Use this Service for illegal purposes.</li>
                <li>Use this Service for malicious purposes.</li>
                <li>Use this service to harm Humankind Inc..</li>
                <li>Impersonate any person or entity that is not yourself</li>
                <li>Harass or intimidate other users.</li>
                <li>Post any content that violates any intellectual property or contract rights.</li>
                <li>Post any inappropriate content including: hate speech, threats, sexually explicit or pornographic content, content that incites violence, or content with nudity or graphic violence.</li>
                <li>Post any racist, bigoted, or hateful content.</li>
            </ul>
            <h2>Intellectual Property
            </h2>
            <p>All content created by Humankind Inc. is subject to copyright laws. The use of the Humankind Inc. logo or other recognizable insignia without prior endorsement is prohibited.
            </p>
            <h2>Before You Sign
            </h2>
            <p>SIGNING THIS AGREEMENT IS AN IMPORTANT DECISION. YOU SHOULD TAKE CAREFUL CONSIDERATION  AND CONSULT WITH APPROPRIATE RESOURCES.
            </p>
        </div>
    );
}

export default Terms;
