import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';

import './App.css';
import Home from './pages/Home'
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Charity from './pages/Charity';


function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/privacy" element={<Privacy/>}/>
                    <Route path="/terms" element={<Terms/>}/>
                    <Route path="/charity" element={<Charity/>}/>

                </Routes>
            </div>
        </Router>
    );
}

export default App;